.container {
    display: flex;
    flex-direction: column;

    &.group {
        position: relative;
    }

    label,
    .label {
        display: flex;
        align-items: unset;

        &.top,
        &.bottom {
            flex-direction: column;
            justify-content: center;
        }

        &.bottom {
            flex-direction: column-reverse;
            .inputLabel {
                margin-top: 2px;
                margin-bottom: 0;
            }
        }

        &.right,
        &.left {
            flex-direction: row;
            align-items: center;

            .inputLabel {
                padding-top: 3px;
            }
        }

        &.left {
            flex-direction: row;
            .inputLabel {
                margin-right: 6px;
                margin-bottom: -4px;
            }
        }

        &.right {
            flex-direction: row-reverse;
            justify-content: flex-end;
            .inputLabel {
                margin-left: 6px;
                margin-bottom: 0;
            }
        }

        .inputLabel {
            display: flex;
            align-items: center;
            margin-bottom: 2px;

            &.group {
                position: relative;
                position: absolute;
                top: 0;
                left: 10px;
                padding: 0 10px;
                transform: translateY(-50%);
                background: var(--primary-background-color);
                z-index: 5;
            }

            .requiredIndicator {
                color: #a61a14;
                font-size: 12px;
                margin-right: 4px;
            }

            .label {
                font-size: 12px;
                color: var(--medium-text-color);
                font-weight: bold;
                display: flex;
                align-items: center;
            }
        }
    }

    .inputContent {
        position: relative;

        &.group {
            border: 1px solid rgba(0, 0, 0, 0.1);
            position: relative;
            padding: 16px;
        }
    }

    .inputContent.error {
        border: 1px solid #d91726;
        border-radius: 6px;
    }

    .disclaimer {
        font-size: 10px;
        font-style: italic;
        color: var(--light-text-color);
        padding: 3px 0 0 5px;
    }

    .messageContainer {
        .message {
            font-size: 11px;
            padding: 6px 8px;
            display: inline-flex;
            align-items: center;
            margin-top: 4px;
            background: #f9d9d9;
            color: #d91726;

            .icon {
                margin-right: 6px;
            }

            .label {
                margin-top: 3px;
            }
        }
    }
}
