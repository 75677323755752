.container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    overflow: auto;
    padding: 50px 0;
    z-index: 1000000000;

    .content {
        background: var(--secondary-background-color);
        width: 520px;
        border-radius: 8px;
        position: relative;
        display: flex;
        flex-direction: column;
        margin: auto;
        box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);

        .modalClose {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 14px;
            right: 14px;
            height: 14px;
            width: 14px;
            cursor: pointer;
            color: var(--xlight-text-color);
            z-index: 10;

            &:hover {
                color: var(--medium-text-color);
            }
        }

        .title {
            /* flex-basis: 72px;
            min-height: 72px;
            font-size: 18px;
            display: flex;
            align-items: center;
            padding: 30px 24px;
            color: var(--medium-text-color);
            font-weight: 500; */

            .iconContainer {
                height: 32px;
                width: 32px;
                font-size: .95rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background: var(--secondary-theme-color);
                color: var(--contrast-medium-text-color);
                border-radius: 6px;
                margin-right: 12px;
            }
        }

        .body {
            flex-grow: 1;

            .bodyContent {
                padding: 8px 24px;
                height: 100%;
                width: 100%;

                @media screen and (max-width: 767px) {
                    & {
                      padding: 8px 16px;
                    }
                  }
            }
        }

        .actions {
            flex-basis: 56px;
            min-height: 56px;
            padding: 12px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
        }
    }
}

@media only screen and (max-width: 550px) {
    .container {
        z-index: 2000000000;

        .content {
            width: 100% !important;
            max-width: 100%;
            height: 100% !important;
            position: absolute;
            top: 0;
            left: 0;
            transform: none;
            overflow-x: hidden;
            border-radius: 0;
        }
    }
}
